import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { UserInfoDataProps } from './userInfo.types';

export const INITIAL_USER_INFO: UserInfoDataProps = {
  availableCountries: [],
  selectedCountry: '',
  selectedVendor: '',
  selectedManufactor: '',
  isManufacturer: false
};

export const setStorageUserInfo = (userInfo: UserInfoDataProps) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.userInfo, JSON.stringify(userInfo));
};

export const getStorageUserInfo = (): UserInfoDataProps | null => {
  const userInfoStored = localStorage.getItem(LOCAL_STORAGE_KEYS.userInfo);
  return userInfoStored ? JSON.parse(userInfoStored) : null;
};

export const removeStorageUserInfo = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.userInfo);
};
