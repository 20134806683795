import { useEffect, useState } from 'react';
import { useAppHeader, useAuthenticationService } from 'admin-portal-shared-services';
import { LoadingDots } from '@hexa-ui/components';
import useGetCountries from '../../services/countries/hooks/useGetCountries/useGetCountries';
import { useBeesContext } from '../../context/BeesContext/BeesContext';

const BeesProviderContainer = ({ children }: any) => {
  const [, setAppHeaderConfig] = useAppHeader();
  const [isSettingHeader, setIsSettingHeader] = useState(true);

  const { supportedCountries } = useAuthenticationService().parseJwt();
  const { userInfo, setUserInfo } = useBeesContext();
  const { response, isLoading } = useGetCountries();

  useEffect(() => {
    if (!response) return;

    const filtredCountries = response.filter(({ code }) => supportedCountries.includes(code));

    if (JSON.stringify(userInfo.availableCountries) !== JSON.stringify(filtredCountries)) {
      setUserInfo({
        ...userInfo,
        availableCountries: filtredCountries
      });
    }

    setAppHeaderConfig({
      countrySelect: true,
      vendorSelect: true,
      defaultCountry: userInfo.selectedCountry ?? '',
      defaultVendor: userInfo.selectedVendor ?? '',
      countryOptions: filtredCountries?.map((avCountry) => avCountry.code)
    });

    setIsSettingHeader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, setAppHeaderConfig]);

  if (isLoading || isSettingHeader) return <LoadingDots dataTestId="loader" />;

  return children;
};

export default BeesProviderContainer;
