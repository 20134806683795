import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Paragraph } from '@hexa-ui/components';
import useGenericDialog from '../../../hooks/useGenericDialog/useGenericDialog';

interface OpenDialogProps {
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const useHeaderDialog = () => {
  const { onOpenDialog, Dialog, onCloseDialog } = useGenericDialog();
  const { formatMessage } = useIntl();

  const openCountryDialog = useCallback(
    ({ onConfirm, onCancel }: OpenDialogProps) => {
      onOpenDialog({
        title: formatMessage({ id: 'countryChangeConfirmationModal.Title' }),
        body: (
          <Paragraph>
            {formatMessage({ id: 'countryChangeConfirmationModal.Description' })}
          </Paragraph>
        ),
        confirmMessage: formatMessage({ id: 'countryChangeConfirmationModal.Confirm' }),
        cancelMessage: formatMessage({ id: 'countryChangeConfirmationModal.Cancel' }),
        buttonConfirmColor: 'destructive',
        onConfirm: () => {
          if (onConfirm) onConfirm();
          onCloseDialog();
        },
        onCancel: () => {
          if (onCancel) onCancel();
          onCloseDialog();
        }
      });
    },
    [formatMessage, onCloseDialog, onOpenDialog]
  );

  const openVendorDialog = useCallback(
    ({ onConfirm, onCancel }: OpenDialogProps) => {
      onOpenDialog({
        title: formatMessage({ id: 'vendorChangeConfirmationModal.Title' }),
        body: (
          <Paragraph>
            {formatMessage({ id: 'vendorChangeConfirmationModal.Description' })}
          </Paragraph>
        ),
        confirmMessage: formatMessage({ id: 'vendorChangeConfirmationModal.Confirm' }),
        cancelMessage: formatMessage({ id: 'vendorChangeConfirmationModal.Cancel' }),
        buttonConfirmColor: 'destructive',
        onConfirm: () => {
          if (onConfirm) onConfirm();
          onCloseDialog();
        },
        onCancel: () => {
          if (onCancel) onCancel();
          onCloseDialog();
        }
      });
    },
    [formatMessage, onCloseDialog, onOpenDialog]
  );

  return {
    openCountryDialog,
    openVendorDialog,
    Dialog
  };
};

export default useHeaderDialog;
