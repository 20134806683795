import { Outlet } from 'react-router-dom';
import { AllLeversProvider } from './context/AllLeversContext/AllLeversContext';
import { BeesContextProvider } from './context/BeesContext/BeesContext';
import BeesProviderContainer from './components/BeesProviderContainer/BeesProviderContainer';

export const AppProviders = () => (
  <BeesContextProvider>
    <BeesProviderContainer>
      <AllLeversProvider>
        <Outlet />
      </AllLeversProvider>
    </BeesProviderContainer>
  </BeesContextProvider>
);
