import { useLogService } from 'admin-portal-shared-services';
import Api from '../../Api/Api';
import { LEVERS_BUSINESS_SERVICE_BASE_URL } from '../../constants';
import { GetSellersById } from './sellersService.d';

export const getSellersById = async ({ manufacturerId, headers }: GetSellersById) => {
  const log = useLogService();

  try {
    const response = await Api.get({
      url: `${LEVERS_BUSINESS_SERVICE_BASE_URL}/sellers/${manufacturerId}`,
      headers
    });

    if ('hasError' in response) throw response;

    return response.data;
  } catch (error) {
    log.error('get-seller-by-manufacturerId - ', error);
    throw error;
  }
};
