/* istanbul ignore file */
/* eslint-disable react-hooks/exhaustive-deps */
import { Heading } from '@hexa-ui/components';
import { appHeaderConfig as headerConfig, useAppHeader } from 'admin-portal-shared-services';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SellerSelector from '../../components/SellerSelector/SellerSelector';
import Styled from '../../components/SellerSelector/SellerSelector.styles';
import { BASE_URL, STEP_ROUTES } from '../../constants';
import { useBeesContext } from '../../context/BeesContext/BeesContext';
import useLeverRoutes, { pagesUrl } from '../../hooks/useLeverRoutes/useLeverRoutes';
import { usePageTitle } from '../../hooks/usePageTitle/usePageTitle';
import { removeSlashFromEnd } from '../../utils/formatters/formatters';
import { getHeadingMessageId } from '../../utils/getHeadingMessageId/getHeadingMessageId';
import { useHeaderDialog } from './useHeaderDialog/useHeaderDialog';

function HeaderService() {
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();
  const { pathname } = useLocation();
  const LEVER_ROUTES = useLeverRoutes();
  const { formatMessage } = useIntl();
  const { getPageTitle } = usePageTitle();
  const navigate = useNavigate();
  const { Dialog, openCountryDialog, openVendorDialog } = useHeaderDialog();
  const [hasOptionalButtons, setHasOptionalButtons] = useState(false);
  const [countryModalIsOpen, setCountryModalIsOpen] = useState(false);
  const [helperUser, setHelperUser] = useState({} as any);
  const {
    userInfo,
    setUserInfo,
    setSellerInfo,
    userMetaData: { data }
  } = useBeesContext();

  const isOfferCreationPage = useMemo(
    () => STEP_ROUTES.some((route) => pathname.includes(route)),
    [pathname]
  );

  useEffect(() => {
    const url = removeSlashFromEnd(pathname);
    const buttons = LEVER_ROUTES.find((r) => r.path === url)?.buttons ?? [];

    setHasOptionalButtons(buttons.length > 0);
    setAppHeaderConfig({
      pageTitle: getPageTitle(url),
      optionalButtons: buttons
    });
  }, [pathname, setAppHeaderConfig]);

  useEffect(() => {
    if (userInfo.selectedCountry) setAppHeaderConfig({ defaultCountry: userInfo.selectedCountry });
  }, [appHeaderConfig.selectedCountry, setAppHeaderConfig]);

  useEffect(() => {
    if (userInfo.selectedVendor) setAppHeaderConfig({ defaultVendor: userInfo.selectedVendor });
  }, [appHeaderConfig.selectedVendor, setAppHeaderConfig]);

  useEffect(() => {
    if (isOfferCreationPage) return;
    if (!appHeaderConfig.selectedCountry) return;
    if (!appHeaderConfig.selectedVendor) return;

    const selectedVendor = data?.vendors?.find(
      (vendor) => vendor.id === appHeaderConfig.selectedVendor
    );

    setUserInfo({
      ...userInfo,
      selectedCountry: appHeaderConfig.selectedCountry,
      selectedVendor: appHeaderConfig.selectedVendor,
      isManufacturer: !!selectedVendor?.isManufacturer
    });
  }, [appHeaderConfig.selectedCountry, appHeaderConfig.selectedVendor]);

  useEffect(() => {
    if (!isOfferCreationPage) return;
    if (!appHeaderConfig.selectedCountry || !userInfo.selectedCountry) return;

    setCountryModalIsOpen(true);

    setHelperUser({
      selectedCountry: appHeaderConfig.selectedCountry
    });

    headerConfig.onChangeSelectedCountry(userInfo.selectedCountry);

    if (countryModalIsOpen) {
      openCountryDialog({
        onConfirm: () => {
          setUserInfo({ ...userInfo, ...helperUser });
          headerConfig.onChangeSelectedCountry(helperUser.selectedCountry);
          setCountryModalIsOpen(false);
          navigate(`${BASE_URL}`);
        },
        onCancel: () => {
          setHelperUser({});
          setCountryModalIsOpen(false);
        }
      });
    }
  }, [appHeaderConfig.selectedCountry]);

  useEffect(() => {
    if (!isOfferCreationPage) return;
    if (!appHeaderConfig.selectedVendor) return;
    if (appHeaderConfig.loadingVendors) return;

    const selectedVendor = data?.vendors?.find((vendor) => vendor.id === userInfo.selectedVendor);

    setHelperUser({
      selectedVendor: appHeaderConfig.selectedVendor,
      isManufacturer: !!selectedVendor?.isManufacturer
    });

    headerConfig.onChangeSelectedVendor(userInfo.selectedVendor);

    openVendorDialog({
      onConfirm: () => {
        setUserInfo({ ...userInfo, ...helperUser });
        headerConfig.onChangeSelectedVendor(helperUser.selectedVendor);
        navigate(`${BASE_URL}`);
      },
      onCancel: () => {
        setHelperUser({});
      }
    });
  }, [appHeaderConfig.selectedVendor]);

  useEffect(() => {
    if (!userInfo.isManufacturer) setSellerInfo();
  }, [userInfo.isManufacturer]);

  const detailsPageBasePath = `${BASE_URL}/${pagesUrl.details}`;
  const isDetailsPage = pathname.startsWith(detailsPageBasePath);
  const headingMessageId = getHeadingMessageId(hasOptionalButtons, isDetailsPage);

  return (
    <>
      <Dialog />
      <Styled.HeaderWrapper hasOptionalButtons={hasOptionalButtons}>
        {headingMessageId && <Heading size="H2">{formatMessage({ id: headingMessageId })}</Heading>}
        {userInfo.isManufacturer && <SellerSelector isOfferCreationPage={isOfferCreationPage} />}
      </Styled.HeaderWrapper>

      <Outlet />
    </>
  );
}

export default HeaderService;
