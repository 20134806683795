import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useBeesContext } from '../../../../context/BeesContext/BeesContext';
import { getSellersById } from '../../sellersService';
import { GetSellersByIdResponse } from '../../sellersService.d';

export default function useGetSellersById(manufacturerId: string) {
  const { userInfo } = useBeesContext();
  const queryClient = useQueryClient();

  const { data, isSuccess, error, isLoading } = useQuery<GetSellersByIdResponse, AxiosError>(
    ['id', manufacturerId],
    () => {
      return getSellersById({
        manufacturerId,
        headers: { vendorId: userInfo.selectedVendor, country: userInfo.selectedCountry }
      });
    },
    {
      staleTime: 3000,
      retry: false,
      enabled: !!manufacturerId,
      onSuccess: async () => {
        await queryClient.refetchQueries(['getAllLevers']);
      }
    }
  );

  return {
    data,
    error,
    isLoading,
    isSuccess
  };
}
