import { LOCAL_STORAGE_KEYS } from '../../../constants';

export const setStorageSellerInfo = (sellerInfo: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.selectedSeller, sellerInfo);
};

export const getStorageSellerInfo = (): string | null => {
  const sellerInfoStored = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedSeller);
  return sellerInfoStored ?? null;
};

export const removeStorageSellerInfo = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedSeller);
};
