/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { getStorageUserInfo } from '../utils/localStorage/userInfo/userInfo';
import { getStorageSellerInfo } from '../utils/localStorage/sellerInfo/sellerInfo';

export interface IRequest {
  url: string;
  body?: Record<string, any> | FormData;
  params?: Record<string, any>;
  headers?: Record<string, any>;
  status?: any;
}

export interface IError extends AxiosError {
  hasError: boolean;
  status?: any;
}

export type IResponse<T> = AxiosResponse<T> | IError;

const initialAxios = Axios.create();
const authentication = useAuthenticationService();
const userInfo = getStorageUserInfo();
const selectedSeller = getStorageSellerInfo();
const parseJwt = authentication.parseJwt();

authentication.enhancedAxios(
  initialAxios as unknown as Parameters<typeof authentication.enhancedAxios>[0],
  {
    headers: [
      {
        country: userInfo?.selectedCountry || parseJwt?.country,
        vendorId: userInfo?.selectedVendor || parseJwt?.vendorId,
        manufacturerId: selectedSeller || undefined,
        noorders: localStorage.getItem('noOrders') || ''
      }
    ]
  }
);
initialAxios.interceptors.request.use((config) => {
  const { headers } = config;

  if (headers.vendorId) {
    return config;
  }

  const userInfo = getStorageUserInfo();
  const selectedSeller = getStorageSellerInfo();
  const authentication = useAuthenticationService();
  const parseJwt = authentication.parseJwt();

  headers.country = userInfo?.selectedCountry || parseJwt?.country || undefined;

  if (userInfo?.isManufacturer) {
    headers.vendorId = selectedSeller || undefined;
    headers.manufacturerId = userInfo?.selectedVendor || parseJwt?.vendorId || undefined;
  } else {
    headers.vendorId = userInfo?.selectedVendor || parseJwt?.vendorId || undefined;
    headers.manufacturerId = undefined;
  }

  return { ...config, headers };
});

const Api = {
  post: <T = any>({ url, body, headers }: IRequest): Promise<IResponse<T>> =>
    initialAxios.post(url, body, { headers }).catch((error: AxiosError) => ({
      hasError: true,
      ...error
    })),

  delete: ({ url }: IRequest): Promise<any> =>
    initialAxios.delete(url).catch((error: AxiosError) => ({ hasError: true, ...error })),

  get: <T = any>({ url, params, headers }: IRequest): Promise<IResponse<T>> =>
    initialAxios.get(url, { params, headers }).catch((error) => ({ hasError: true, ...error })),

  put: ({ url, body, headers }: IRequest): Promise<any> =>
    initialAxios.put(url, body, { headers }).catch((error) => ({
      hasError: true,
      ...error
    })),

  patch: ({ url, body, headers }: IRequest): Promise<any> =>
    initialAxios.patch(url, body, { headers }).catch((error) => ({
      hasError: true,
      ...error
    }))
};

export default Api;
