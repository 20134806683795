import { useLocalStorage, UserMetadata, useUserMetadata } from 'admin-portal-shared-services';
import { createContext, useContext, useMemo } from 'react';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import useSellerInfo from '../../hooks/useSellerInfo/useSellerInfo';
import { INITIAL_USER_INFO } from '../../utils/localStorage/userInfo/userInfo';
import { UserInfoDataProps } from '../../utils/localStorage/userInfo/userInfo.types';

interface BeesContextProviderProps {
  userInfo: UserInfoDataProps;
  setUserInfo: (userInfo?: UserInfoDataProps) => void;
  sellerInfo: string | null;
  setSellerInfo: (sellerInfo?: string) => void;
  userMetaData: { data: UserMetadata | undefined; isLoading: boolean };
}

const BeesContext = createContext<BeesContextProviderProps>({} as BeesContextProviderProps);

export const BeesContextProvider = ({ children }) => {
  const { data: userMetaData, isLoading: isLoadingUserMetaData } = useUserMetadata();

  const [userInfo, setUserInfo] = useLocalStorage(
    LOCAL_STORAGE_KEYS.userInfo,
    INITIAL_USER_INFO as any
  );

  const { sellerInfo, setSellerInfo } = useSellerInfo();

  const memoValue = useMemo((): BeesContextProviderProps => {
    return {
      userInfo,
      setUserInfo,
      sellerInfo,
      setSellerInfo,
      userMetaData: { data: userMetaData, isLoading: isLoadingUserMetaData }
    };
  }, [userInfo, setUserInfo, sellerInfo, setSellerInfo, userMetaData, isLoadingUserMetaData]);

  return <BeesContext.Provider value={memoValue}>{children}</BeesContext.Provider>;
};

/* istanbul ignore next */
export const useBeesContext = () => {
  const context = useContext(BeesContext);
  return context;
};
