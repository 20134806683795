import { useCallback, useEffect, useState } from 'react';
import {
  getStorageSellerInfo,
  removeStorageSellerInfo,
  setStorageSellerInfo
} from '../../utils/localStorage/sellerInfo/sellerInfo';

const useSellerInfo = () => {
  const [sellerInfo, setSellerInfoState] = useState<string | null>(() => {
    return getStorageSellerInfo();
  });

  const setSellerInfo = useCallback((sellerInfo?: string) => {
    if (sellerInfo === undefined) {
      removeStorageSellerInfo();
      setSellerInfoState(null);
    } else {
      setStorageSellerInfo(sellerInfo);
      setSellerInfoState(sellerInfo);
    }
  }, []);

  useEffect(() => {
    const storedSellerInfo = getStorageSellerInfo();
    if (storedSellerInfo !== sellerInfo) {
      setSellerInfoState(storedSellerInfo);
    }
  }, [sellerInfo]);

  return { sellerInfo, setSellerInfo };
};

export default useSellerInfo;
