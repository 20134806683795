import { BASE_URL } from '../../constants';
import { pagesUrl } from '../useLeverRoutes/useLeverRoutes';

const HOME_PATH = '/';

const breadcrumbLabel = 'Breadcrumb.CampaignList'

const breadcrumbItemsForHomePage = [{ label: breadcrumbLabel, path: BASE_URL }];

const breadcrumbItemsForNewCampaignPage = [
  { label: breadcrumbLabel , path: BASE_URL },
  { label: 'NewCampaign.Heading', path: `${BASE_URL}/${pagesUrl.campaignStrategy}` }
];

const breadcrumbItemsForDetailsPage = [
  { label: breadcrumbLabel, path: BASE_URL },
  { label: 'DetailsPage.Title', path: `${BASE_URL}/${pagesUrl.details}` }
];

export {
  HOME_PATH, breadcrumbItemsForDetailsPage, breadcrumbItemsForHomePage,
  breadcrumbItemsForNewCampaignPage
};

